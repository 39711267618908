<template>
  <div class="container">
    <div class="nav_box">
      <div
        :class="nav === 1 ? 'current' : 'nav'"
        @click="changeNav(1)"
      >当前异常</div>
      <div
        :class="nav === 2 ? 'current' : 'nav'"
        @click="changeNav(2)"
      >历史异常</div>
    </div>

    <div class="content">
      <!-- <keep-alive> -->
      <Component :is="getCpt" />
      <!-- </keep-alive> -->
    </div>
  </div>
</template>

<script>
import TabOne from './tab_one.vue'
import TabTwo from './tab_two.vue'

export default {
  data() {
    return {
      nav: 1
    }
  },
  methods: {
    changeNav(nav) {
      if (this.nav === nav) return
      this.nav = nav
    }
  },
  computed: {
    getCpt() {
      return this.nav === 1 ? TabOne : TabTwo
    }
  }
}
</script>

<style lang='scss' scoped>
.container {
  padding: 12px;
}

.nav_box {
  height: 60px;
  padding-top: 6px;
  background-color: #fff;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #EFF2F7;

  .nav {
    height: 54px;
    line-height: 54px;
    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    margin: 0 25px;
    cursor: pointer;
  }

  .current {
    @extend .nav;
    color: #2A47B0;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      width: 65px;
      height: 4px;
      background: #2A47B0;
    }
  }
}

.content {
  width: 100%;
  min-height: calc(100vh - 188px);
  background-color: #fff;
}
</style>
