<template>
  <div>
    <div class="anomaly_statistics">
      <!-- 选择器 -->
      <div class="select_box">
        <!-- 运营商 -->
        <el-select
          v-supAdmin
          class="ipt_box mr12 mt12"
          v-model="id_operator"
          placeholder="请选择运营商"
          @change="selectOperator"
        >
          <el-option
            v-for="ite in operatorList"
            :key="ite.id"
            :label="ite.name_operator"
            :value="ite.id_operator"
          ></el-option>
        </el-select>

        <!-- 项目 -->
        <el-select
          v-model="id_project"
          placeholder="请选择项目"
          @change="selectProject"
          class="ipt_box mt12 mr12"
        >
          <el-option
            v-for="ite in filterProjectList"
            :key="ite.id"
            :label="ite.name_project"
            :value="ite.id"
          ></el-option>
        </el-select>

        <!-- 系统 -->
        <el-select
          class="ipt_box mr12 mt12"
          v-model="id_device"
          placeholder="请选择系统"
          :disabled="id_project === ''"
        >
          <el-option
            v-for="(ite, idx) in systemList"
            :key="idx"
            :label="ite.name"
            :value="ite.id_device"
          ></el-option>
        </el-select>

        <!-- 异常类型 -->
        <el-select
          class="ipt_box mr12 mt12"
          v-model="type_err"
          placeholder="请选择异常类型"
        >
          <el-option
            v-for="ite in typeErrList"
            :key="ite.id"
            :label="ite.name"
            :value="ite.id"
          ></el-option>
        </el-select>

        <!-- 推送方式 -->
        <el-select
          class="ipt_box mr12 mt12"
          v-model="push_type"
          placeholder="请选择推送方式"
        >
          <el-option
            v-for="ite in pushTypeList"
            :key="ite.id"
            :label="ite.name"
            :value="ite.id"
          ></el-option>
        </el-select>

        <!-- 预警类型 -->
        <el-select
          class="ipt_box mr12 mt12"
          v-model="warn_level"
          placeholder="请选择预警类型"
        >
          <el-option
            v-for="ite in warnLevelList"
            :key="ite.id"
            :label="ite.name"
            :value="ite.id"
          ></el-option>
        </el-select>

        <el-date-picker
          class="time_box mr12 mt12"
          v-model="date"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>

        <div class="mr12 mt12">
          <el-button
            type="primary"
            size="small"
            @click="search"
          >查询</el-button>
          <el-button
            size="small"
            type="primary"
            plain
            @click="reset"
          >重置</el-button>
          <el-button
            size="small"
            type="success"
            plain
            @click="exportExcel"
          >导出</el-button>
        </div>
      </div>

      <!-- 表格数据 -->
      <div class="table_box">
        <el-table
          :data="errorList"
          style="width: 100%"
          border
          :header-cell-style="{
            background: '#fafafb',
            height: '40px'
          }"
          :stripe="true"
          size="small"
        >
          <el-table-column
            prop="name"
            label="系统"
          ></el-table-column>

          <el-table-column label="异常类型/阈值">
            <template v-slot="scope">
              <span>{{ scope.row.type_err | typeErrFmt }}</span>
              <span style="color: #f34e46">
                {{ scope.row.trigger_value }}{{ scope.row.type_err | unitFmt }}
              </span>
            </template>
          </el-table-column>

          <el-table-column label="预警持续时间">
            <template v-slot="scope">
              <span>{{ scope.row.time_duration || '-' }}(min)</span>
            </template>
          </el-table-column>

          <el-table-column
            label="预警类型/范围"
            min-width="100"
          >
            <template v-slot="scope">
              <span>{{ scope.row.warn_name }}</span>
              <span>({{ getRange(scope.row) }})</span>
            </template>
          </el-table-column>

          <el-table-column label="推送方式">
            <template v-slot="scope">
              <span>{{ scope.row.push_type | pushTypeFmt }}</span>
            </template>
          </el-table-column>

          <el-table-column
            prop="name_project"
            label="项目"
          ></el-table-column>

          <el-table-column label="通知时间">
            <template v-slot="scope">
              <span>{{ scope.row.time_notify || '-' }}</span>
            </template>
          </el-table-column>

          <el-table-column
            prop="time_start"
            label="系统预警时间"
          ></el-table-column>
        </el-table>
      </div>

      <!--分页-->
      <div class="pagination_box">
        <el-pagination
          @size-change="sizeChange"
          @current-change="CurrentChange"
          :current-page="page_id + 1"
          :page-sizes="[20, 50, 100, 200]"
          :page-size="page_num"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <ExportExcel ref="exportExcelRef" />
  </div>
</template>

<script>
import { GetProjectList, GetErrorList, GetOperatorList2 } from '@/api'
import ExportExcel from '@/components/export_excel/export_excel.vue'

export default {
  name: 'anomaly_statistics',
  components: { ExportExcel },
  data() {
    return {
      // 角色权限
      role: '',
      // 运营商列表
      operatorList: [],
      // 当前选中的运营商
      id_operator: '',
      // 选中的项目
      id_project: '',
      // 系统id
      id_device: '',
      // 异常类型
      type_err: '',
      // 异常类型列表 1：水位、2：水温、3：回水温、3：供水电流
      typeErrList: [
        { id: 1, name: '水位' },
        { id: 2, name: '水温' },
        { id: 3, name: '回水温' },
        { id: 4, name: '供水电流' }
      ],
      // 推送方式
      push_type: '',
      pushTypeList: [
        { id: 1, name: '短信' },
        { id: 2, name: 'APP' },
        { id: 3, name: '同时推送' }
      ],
      // 预警类型
      warn_level: '',
      // 预警类型列表1：默认预警 2：自定义预警
      warnLevelList: [
        { id: 1, name: '默认预警' },
        { id: 2, name: '自定义预警' }
      ],
      // 添加日期
      date: ['', ''],
      page_id: 0,
      page_num: 20,
      total: 0,
      // 异常列表
      errorList: [],
      // 项目列表
      projectList: [],
      // 当前项目下的系统列表
      systemList: []
    }
  },
  methods: {
    // 超级管理员进来获取运营商列表
    async getOperatorList() {
      const { ret, data, msg } = await GetOperatorList2()
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.operatorList = data.data
    },

    // 选中运营商
    selectOperator() {
      this.id_project = ''
    },

    // 页面显示多少条数据
    sizeChange(s) {
      this.page_num = s
      this.page_id = 0
      this.getErrList()
    },

    // 页码变化
    CurrentChange(p) {
      this.page_id = p - 1
      this.getErrList()
    },

    // 查询
    search() {
      this.page_id = 0
      this.getErrList()
    },

    // 重置
    reset() {
      if (this.role === 100) {
        this.id_operator = ''
      }
      this.id_project = this.id_device = this.type_err = this.warn_level = this.push_type =
        ''
      this.date = null
      this.page_id = 0
      this.getErrList()
    },

    // 获取项目列表
    async getProjectList() {
      const { ret, data, msg } = await GetProjectList()
      if (ret !== 0) {
        return this.$message.error(msg || '获取项目列表失败!')
      }
      this.projectList = data.data
    },

    // 选择了项目
    selectProject(id) {
      this.id_device = ''
      const index = this.projectList.findIndex(i => i.id === id)
      this.systemList = this.projectList[index].device_list
    },

    // 获取参数列表
    getParams() {
      const params = {
        page_id: this.page_id,
        page_num: this.page_num,
        time_stop: 1
      }
      if (this.role === 100 && this.id_operator) {
        params.id_operator = this.id_operator
      }
      if (this.id_project) {
        params.id_project = this.id_project
      }
      if (this.id_device) {
        params.id_device = this.id_device
      }
      if (this.type_err) {
        params.type_err = this.type_err
      }
      if (this.push_type) {
        params.push_type = this.push_type
      }
      if (this.warn_level) {
        params.warn_level = this.warn_level
      }
      if (this.date && this.date[0] && this.date[1]) {
        params.time_start = this.date[0] + ' 00:00:00'
        params.time_end = this.date[1] + ' 23:59:59'
      }
      return params
    },

    // 获取异常列表
    async getErrList() {
      const params = this.getParams()
      const { ret, data, msg } = await GetErrorList(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.total = data.cnt_all
      this.errorList = data.data
    },

    // 获取异常范围
    getRange(row) {
      const unit = ['％', '℃', '℃', 'A']
      return `小于${row.warn_min}${unit[row.type_err - 1]}，大于${row.warn_max}${unit[row.type_err - 1]}`
    },

    // 导出excel
    exportExcel() {
      const params = this.getParams()
      this.$refs.exportExcelRef.export({
        fn: GetErrorList,
        params,
        name: '异常统计',
        keyMap: {
          name: '系统',
          type_err: '异常类型',
          trigger_value: '阀值',
          name_project: '项目',
          time_duration: '预警持续时间(min)',
          warn_level: '预警类型',
          warn_min: '预警最小值',
          warn_max: '预警最大值',
          push_type: '推送方式',
          time_notify: '通知时间',
          time_start: '系统预警时间'
        },
        dataMap: ['data'],
        totalMap: ['cnt_all'],
        valueFmt: {
          type_err(v) {
            switch (v) {
              case 1:
                return '水位'
              case 2:
                return '水温'
              case 3:
                return '回水温'
              case 4:
                return '供水电流'
            }
          },
          warn_level(v) {
            return v === 1 ? '默认预警' : '自定义预警'
          },
          push_type(v) {
            if (v === 1) {
              return '短信'
            } else if (v === 2) {
              return 'APP'
            } else {
              return '短信/APP'
            }
          }
        }
      })
    }
  },
  created() {
    this.role = this.$store.getters.role
    if (this.role === 100) {
      this.getOperatorList()
    }
    this.getProjectList()
    this.getErrList()
  },

  computed: {
    // 选择运营商后 过滤项目
    filterProjectList() {
      if (this.id_operator) {
        return this.projectList.filter(i => i.id_operator === this.id_operator)
      } else {
        return this.projectList
      }
    }
  },

  filters: {
    // 异常类型
    typeErrFmt(v) {
      const t = ['水位', '水温', '回水温', '供水电流']
      return t[v - 1]
    },
    // 单位格式化
    unitFmt(v) {
      const t = ['％', '℃', '℃', 'A']
      return t[v - 1]
    },

    // 推送方式格式化
    pushTypeFmt(v) {
      const pushType = []
      if (v & 1) {
        pushType.push('短信')
      }
      if (v & 2) {
        pushType.push('APP')
      }
      return pushType.join('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.anomaly_statistics {
  width: 100%;
  min-height: 100%;
  border-radius: $radius;
  background-color: #fff;
  padding: 12px;
}

.table_box {
  padding: 0 12px;
  margin-top: 12px;
}
</style>
